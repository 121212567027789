<template>
  <div>
    <div class="flex flex-wrap border border-blue-200">
      <div
        class="w-full sm:w-1/2 md:w-1/2 xl:w-1/3 border-b sm:border-b-0 sm:border-r border-blue-200 py-4 px-6"
      >
        <div
          class="flex justify-end cursor-pointer text-12 text-blue-500 font-bold"
          @click="showProfile = true"
        >
          Edit Profile
        </div>
        <div class="mb-5">
          <sm-loader v-if="merchant.loading" />
          <div class="flex items-center" v-else>
            <div>
              <div
                v-if="profile.image"
                class="flex justify-center items-center mb-3"
              >
                <div
                  class="rounded border border-gray-500 w-160px h-56px p-3"
                  :style="{
                    'background-image': 'url(' + profile.image + ')',
                    ...style
                  }"
                />
              </div>
              <div class="mb-3">
                <div
                  class="text-sm text-blue-500 cursor-pointer"
                  @click="$refs.changeImageModal.open()"
                >
                  {{ profile.image ? 'Change Image' : 'Upload Image' }}
                </div>
              </div>
              <h1 class="text-2xl font-bold mb-1">
                {{ profile.business_name }}
              </h1>
              <span
                class="text-sm cursor-pointer"
                v-if="description.description"
              >
                {{ description.description }}
              </span>
            </div>
          </div>
        </div>

        <div class="mb-5">
          <div class="mb-5">
            <div class="opacity-75 text-sm font-medium mb-1">
              Account Verification Status
            </div>
            <sm-loader v-if="merchant.loading" />
            <div v-else>
              <div
                class="text-gray-500 text-sm font-light flex flex-wrap items-center"
              >
                <ion-icon name="alert-circle-outline" class="text-lg mr-2" />
                <div v-html="profile && checkStatus(profile.status)"></div>
              </div>
              <div class="flex items-start mt-5">
                <span class="text-gray-500 text-sm font-light mr-2 mt-2">
                  Change Status:
                </span>
                <div class="flex-grow">
                  <custom-select
                    :options="verificationStatusOptions"
                    class="inline-flex relative text-sm text-green-500 border border-green-500 rounded-sm pl-3 py-2 pr-3 mr-3 mb-3 align-middle"
                    v-model="statusForm.data.status.value"
                    placeholder="Set verification status"
                  />

                  <button
                    type="button"
                    class="btn btn-sm btn-blue align-middle -mt-2"
                    :disabled="statusForm.loading"
                    @click="saveVerificationStatus"
                    v-if="statusForm.changed"
                  >
                    <sm-loader
                      class="sm-loader-white"
                      v-if="statusForm.loading"
                    />
                    <span v-else>Save</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-5 flex flex-row">
          <label class="custom-switch">
            <input
              type="checkbox"
              class="custom-switch-input"
              v-model="merchant.featured"
              @change="changeFeaturedStatus"
            />
            <span class="custom-switch-label"></span>
          </label>
          <span class="text-sm mt-1 ml-1">Add As Featured Category</span>
        </div>

        <div class="border-t border-blue-200 mb-5 pt-4">
          <div class="opacity-75 text-sm font-medium mb-1">
            Fullname
          </div>
          <sm-loader v-if="merchant.loading" />
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
            v-else
          >
            <ion-icon name="person-circle-outline" class="text-lg mr-2" />
            {{ profile.first_name }} {{ profile.last_name }}
          </div>
        </div>

        <div class="border-t border-blue-200 mb-5 pt-4">
          <div class="opacity-75 text-sm font-medium mb-1">
            Business Email
          </div>
          <sm-loader v-if="merchant.loading" />
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
            v-else
          >
            <ion-icon name="person-circle-outline" class="text-lg mr-2" />
            {{ description.workplace_email || 'N/A' }}
          </div>
        </div>

        <div class="border-t border-blue-200 mb-5 pt-4">
          <div class="opacity-75 text-sm font-medium mb-1">
            Category
          </div>
          <sm-loader v-if="merchant.loading" />
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
            v-else
          >
            <ion-icon name="person-circle-outline" class="text-lg mr-2" />
            {{ industry ? industry.name : 'No Category' }}
          </div>
        </div>
        <div class="border-t border-blue-200 mb-5 pt-4">
          <div class="opacity-75 text-sm font-medium mb-1">
            Email Address
          </div>
          <sm-loader v-if="merchant.loading" />
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
            v-else
          >
            <ion-icon name="mail-outline" class="text-lg mr-2" />
            {{ profile.email }}
          </div>
        </div>
        <div class="border-t border-blue-200 mb-5 pt-4">
          <div class="opacity-75 text-sm font-medium mb-1">
            Website
          </div>
          <sm-loader v-if="merchant.loading" />
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
            v-else
          >
            <ion-icon name="logo-web-component" class="text-lg mr-2" />
            {{ profile.website ? profile.website : 'No website available' }}
          </div>
        </div>
        <div class="border-t border-blue-200 mb-5 pt-4">
          <div class="opacity-75 text-sm font-medium mb-1">
            Phone Number
          </div>
          <sm-loader v-if="merchant.loading" />
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
            v-else
          >
            <ion-icon name="call-outline" class="text-lg mr-2" />
            {{ profile.phone ? profile.phone : 'No Phone Number' }}
          </div>
        </div>

        <div class="border-t border-blue-200 mb-5 pt-4">
          <div class="opacity-75 text-sm font-medium mb-1">
            Address
          </div>
          <sm-loader v-if="merchant.loading" />
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
            v-else
          >
            <ion-icon name="globe-outline" class="text-lg mr-2" />
            {{ profile.address ? profile.address : 'No Address Provided' }}
          </div>
        </div>

        <div class="border-t border-blue-200 mb-5 pt-4">
          <div class="opacity-75 text-sm font-medium mb-1">
            Source
          </div>
          <sm-loader v-if="merchant.loading" />
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
            v-else
          >
            <ion-icon name="cash-outline" class="text-lg mr-2" />
            {{ profile.source | sentenceCase }}
          </div>
        </div>

        <div class="border-t border-blue-200 mb-5 pt-4">
          <div class="opacity-75 text-sm font-medium mb-1">
            Date Registered
          </div>
          <sm-loader v-if="merchant.loading" />
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
            v-else
          >
            <ion-icon name="calendar-outline" class="text-lg mr-2" />
            {{ profile.created_at | dateFormat('d M, Y') }}
          </div>
        </div>

        <div
          class="border-t border-blue-200 mb-5 pt-4"
          v-if="
            profile.approved_at || profile.rejected_at || profile.suspended_at
          "
        >
          <div class="opacity-75 text-sm font-medium mb-1">
            Date {{ merchantVerificationStatus }}
          </div>
          <sm-loader v-if="merchant.loading" />
          <div
            class="text-gray-500 text-sm font-light flex flex-row items-center"
            v-else
          >
            <ion-icon name="calendar-outline" class="text-lg mr-2" />
            {{
              profile.approved_at || profile.rejected_at || profile.suspended_at
            }}
          </div>
        </div>
      </div>
      <div
        class="w-full sm:w-1/2 md:w-1/2 xl:w-2/3 py-4 px-6"
        style="min-height: 600px"
      >
        <loader v-if="merchant.loading" />
        <template v-else>
          <tabs v-model="tab" :tabs="tabs" class="mb-6" />
          <component :is="tab" :profile="profile" @success="getMerchant" />
        </template>
      </div>
    </div>

    <modal className="w-11/12 sm:w-400px rounded" ref="changeImageModal">
      <div class="lg:-mx-8">
        <h1 class="text-18 text-left font-bold mb-2">
          Update {{ profile.business_name }} Image
        </h1>
        <div class="mt-10">
          <template v-if="getFormError(changeImageForm)">
            <div class="alert alert-red-soft mb-10">
              <span class="alert-icon">!</span>
              <span>{{ getFormError(changeImageForm) }}</span>
            </div>
          </template>

          <div class="flex flex-col">
            <dropzone
              class="mb-0 mr-2 w-full"
              v-model="changeImageForm.data.image.value"
              accept="*"
            />
            <button
              type="button"
              class="btn btn-blue py-5 mx-16 mt-16"
              @click.prevent="changeImage"
              :disabled="changeImageForm.loading"
            >
              <sm-loader
                class="sm-loader-white"
                v-if="changeImageForm.loading"
              />
              <span class="text-sm" v-else>Submit</span>
            </button>

            <div
              class="form-group-2-text text-red-500 mb-2"
              v-if="getFirstError('image', changeImageForm)"
            >
              {{ getFirstError('image', changeImageForm) }}
            </div>
          </div>
        </div>
      </div>
    </modal>

    <update-profile :profile="profile" v-if="showProfile" />
  </div>
</template>
<script>
export default {
  components: {
    settlements: () => import('./Cashback'),
    'cash-usages': () => import('./CashUsage'),
    'equity-contribution': () => import('./EquityContribution'),
    admins: () => import('./Admin'),
    transactions: () => import('./Transaction'),
    branch: () => import('../location/Index.vue'),
    profile: () => import('./Profile'),
    deals: () => import('../deals/Index'),
    settings: () => import('./Settings'),
    'update-profile': () => import('../components/UpdateProfile'),
    'business-kyc': () => import('./business-kyc/Index')
  },
  data() {
    return {
      merchant: this.$options.resource([], {
        featured: null
      }),
      tabs: [
        { name: 'profile', title: 'Profile' },
        { name: 'business-kyc', title: 'Business Kyc' },
        { name: 'settlements', title: 'Settlements' },
        { name: 'admins', title: 'Admins' },
        { name: 'branch', title: 'Branch & Region' },
        { name: 'transactions', title: 'Transfer Transactions' },
        { name: 'equity-contribution', title: 'Equity Contribution' },
        { name: 'deals', title: 'Deals' },
        { name: 'settings', title: 'Settings' }
      ],
      tab: 'profile',
      statusForm: this.$options.basicForm(['status'], {
        changed: false
      }),
      changeImageForm: this.$options.basicForm([
        { name: 'image', value: null }
      ]),
      showProfile: false
    };
  },
  computed: {
    merchantId() {
      return this.$route.params.id;
    },
    profile() {
      return this.merchant.data;
    },
    merchantVerificationStatus() {
      if (this.profile?.approved_at) {
        return 'Approved';
      } else if (this.profile?.rejected_at) {
        return 'Rejected';
      } else if (this.profile?.suspended_at) {
        return 'Suspended';
      } else {
        return null;
      }
    },
    industry() {
      return this.profile?.industry;
    },
    description() {
      return this.profile?.descriptions;
    },
    verificationStatusOptions() {
      return [
        { title: 'Approve', value: 'approved' },
        { title: 'Suspend', value: 'suspended' },
        { title: 'Reject', value: 'rejected' }
      ];
    },
    style() {
      return {
        'background-size': 'contain',
        'background-position': 'center',
        'background-repeat': 'no-repeat'
      };
    }
  },
  watch: {
    'statusForm.data.status.value'(value) {
      if (value.match(/pending/i)) {
        return (this.statusForm.changed = false);
      }
      this.statusForm.changed = value != this.merchant?.status ? true : false;
    },
    'profile.featured'(value) {
      this.merchant.featured = value;
    }
  },
  beforeMount() {
    this.getMerchant();
  },
  methods: {
    async getMerchant() {
      this.merchant.loading = true;
      await this.$get({
        url: `${this.$baseurl}/admin/merchants/${this.merchantId}`,
        headers: this.headers,
        success: response => {
          this.merchant.data = response.data.data;
        },
        error: error => {
          console.log(error);
        }
      });
      this.merchant.loading = false;
    },
    async saveVerificationStatus() {
      const status = this.statusForm.data.status.value;
      await this.$get({
        url: `${this.$baseurl}/admin/merchants/${this.merchantId}/${status}`,
        headers: this.headers,
        success: async () => {
          this.statusForm.changed = false;
          await this.getMerchant();
        },
        error: error => {
          console.log(error);
        }
      });
    },
    async changeFeaturedStatus() {
      await this.sendRequest(
        'admin.merchants.changeFeaturedStatus',
        this.merchantId,
        this.merchant.featured ? 1 : 0,
        {
          success: () => this.getMerchant(),
          error: error => console.log(error)
        }
      );
    },
    async changeImage() {
      if (!this.validateForm(this.changeImageForm)) return false;

      this.changeImageForm.error = false;
      this.changeImageForm.loading = true;

      const image =
        this.changeImageForm.data.image.value === null
          ? ''
          : this.changeImageForm.data.image.value;

      const data = new FormData();
      data.append('merchant_id', this.profile?.id);
      data.append('image', image);

      await this.$post({
        url: `${this.$baseurl}/admin/merchants/update-image`,
        headers: this.headers,
        data: data,
        success: async () => {
          this.$refs.changeImageModal.close();
          await this.getMerchant();
        },
        error: error => console.log(error)
      });
      this.changeImageForm.loading = false;
    },
    checkStatus(status) {
      switch (status) {
        case 'approved':
          return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
                    Approved
                  </div>`;
        case 'suspended':
          return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
                    Suspended
                  </div>`;
        case 'rejected':
          return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-3 py-2 inline-block">
                    Rejected
                  </div>`;
        case 'pending':
        default:
          return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
                    Pending
                  </div>`;
      }
    },
    closeProfile() {
      this.showProfile = false;
    }
  }
};
</script>
